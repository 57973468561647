<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学习轨迹清除</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:10px">
            <div title="班级搜索" style="display: flex; align-items: center"
              class="searchboxIclearProjectSearchModeltem ci-full">
              <span class="itemLabel">班级搜索:</span>
              <el-select v-model="projectId" placeholder="请选择" remote size="small" clearable
                :remote-method="superPorjectSelect" @visible-change="clearProjectSearchModel">
                <template>
                  <div class="select-header">
                    <span class="select-header-1">班级编号</span>
                    <span class="select-header-2">班级名称</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="projectCode" v-on:input="superPorjectSelect" type="text" size="small"
                      placeholder="班级编号" clearable style="width: 150px" />
                    <el-input v-model="projectName" v-on:input="superPorjectSelect" type="text" size="small"
                      placeholder="请输入班级名称" clearable style="margin-left: 50px; width: 250px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName"
                  :value="item.projectId">
                  <span class="select-header-3" style="width: 150px; font-size: 13px">{{ item.projectCode }}</span>
                  <span class="select-header-4" style="width: 250px; margin-left: 50px; font-size: 13px">{{
                    item.projectName }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="学员搜索" style="display: flex; align-items: center" class="searchboxItem ci-full">
              <span class="itemLabel">学员搜索:</span>
              <el-select v-model="userId" placeholder="请选择" no-data-text="没有数据" remote size="small" clearable
                :remote-method="superUserSelect" @visible-change="clearUserSearchModel">
                <template>
                  <div class="select-header">
                    <span style="color: #8492a6;float: left;font-size: 13px;width: 100px;">学员姓名</span>
                    <span style="color: #8492a6;float: left;font-size: 13px;margin-left: 50px;width: 140px;">身份证号码</span>
                    <span style=" color: #8492a6; float: left;font-size: 13px;margin-left: 50px;width: 100px;">学员电话</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="userName" v-on:input="superUserSelect" type="text" size="small" placeholder="学员姓名"
                      clearable style="width: 100px" />
                    <el-input v-model="idcard" v-on:input="superUserSelect" type="text" size="small" placeholder="身份证号码"
                      clearable style="margin-left: 50px; width: 140px" />
                    <el-input v-model="mobile" v-on:input="superUserSelect" type="text" size="small" placeholder="学员电话"
                      clearable style="margin-left: 50px; width: 100px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in userList" :key="index" :label="item.userName" :value="item.userId">
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.idcard }}</span>
                  <span style="width: 100px; margin-left: 50px; font-size: 13px">{{ item.mobile }}</span>
                </el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
          </div>
          <div class="searchbox" style="margin-bottom:10px">
            <div title="班级搜索" style="display: flex; align-items: center"
              class="searchboxIclearProjectSearchModeltem ci-full">
              <span class="itemLabel" style="color: red;">注：此功能仅限上海班级使用</span>
            </div>
            
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="60" fixed :index="indexMethod" />
              <el-table-column label="学员姓名" align="left" show-overflow-tooltip prop="userName" width="120" fixed />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" width="200" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" width="160" />
              <el-table-column label="课件名称" align="left" prop="catalogName" ming-width="200" />
              <el-table-column label="视频总时长" align="left" prop="kpointDuration" width="140">
                <template slot-scope="scope">
                  {{ getTime(scope.row.kpointDuration) }}
                </template>
              </el-table-column>
              <el-table-column label="学习视频时长" align="left" prop="timeInterval" width="140">
                <template slot-scope="scope">
                  {{ getTime(scope.row.timeInterval) }}
                </template>
              </el-table-column>
              <el-table-column label="开始学习时间" align="left" prop="startTime" width="150" />
              <el-table-column label="结束学习时间" align="left" prop="createTime" width="160" />
              <el-table-column label="操作" align="center" min-width="100" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="handleDel(scope.row)">删除</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "classListCom_clearLearningTrajectory",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 班级id
      projectId: "",
      // 班级编码
      projectCode: "",
      // 班级名称
      projectName: "",
      // 班级下拉数据
      projectList: [{}],
      // 学员id 
      userId: "",
      // 学员名称
      userName: "",
      // 身份证
      idcard: "",
      // 手机号
      mobile: "",
      // 学员下拉数据
      userList: [{}],
    }
  },
  computed: {

  },
  mounted() {
  },
  created() {

  },
  methods: {
    // 查询 - 班级列表
    superPorjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.projectName,
          projectCode: this.projectCode,
        },
      ).then((res) => {
        if (res.data.list.length < 1) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    // 查询 - 班级列表 - 下拉框出现/隐藏时触发
    clearProjectSearchModel(e) {
      if (e) {
        this.projectName = "";
        this.projectCode = "";
        this.superPorjectSelect();
      }
    },
    // 查询 - 学员列表
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.userName,
          idcard: this.idcard,
          mobile: this.mobile,
          projectId: this.projectId
        }
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    // 查询 - 学员列表 - 下拉框出现/隐藏时触发
    clearUserSearchModel(e) {
      if (e) {
        this.userName = "";
        this.idcard = "";
        this.mobile = "";
        this.superUserSelect();
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      if (!this.projectId) {
        this.$message.error('请选择班级后查询！')
        return
      }
      if (!this.userId) {
        this.$message.error('请选择学员后查询！')
        return
      }
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      if (this.userId) {
        params.userId = this.userId;
      }
      this.doFetch({
        url: "/biz/project/projectUser/clean/pageList",
        params,
        pageNum,
      });
    },
    // 删除
    handleDel(el) {
      this.$confirm("此操作将永久删除该学习记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      })
        .then(() => {
          this.$post("/biz/project/projectUser/clean", { trackId: el.trackId }).then(
            (res) => {
              this.getData(this.pageNum);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //时间转换 秒转时分秒
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
        (m != "00" ? m + "分" : "") +
        (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
};
</script>
<style lang="less" scoped>
.itemLabel {
  min-width: 5rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}

.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;

  .select-header-1 {
    color: #8492a6;
    float: left;
    font-size: 13px;
    width: 150px;
  }

  .select-header-2 {
    color: #8492a6;
    float: left;
    font-size: 13px;
    margin-left: 50px;
    width: 250px;
  }
}
</style>
